import {ASSETS} from "../common/images";
import * as React from "react";

const ContactDetails = () => {
    return (
        <div className="col-md-12 mb-md-5 mb-3">
            <div className="contact-details-address-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="common-contact-us-header-wrapper">
                            <h3 className="gb-color-red title text-uppercase mb-md-4 mb-2">We would like to hear from you</h3>
                            <p className="gb-color-grey gb-description-med mb-md-5 mb-3">You can either call us on the numbers given below, skype us, mail us or directly visit us in person on the below address.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact-address-box-wrapper">
                            <div className="row">
                                <div className="col-12 col-sm-6 pe-md-4 mb-md-5 mb-3">
                                    <div className="common-contact-address-box">
                                        <img src={ASSETS.CONTACTUS.Map} alt="" className="common-contact-icon map-icon" />
                                        <p className="title text-uppercase gb-color-red">visit here</p>
                                        <p className="description gb-color-grey">Plot No 759, Third Floor, Sector 82, JLPL Industrial Area, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 ps-md-4 mb-md-5 mb-3">
                                    <div className="common-contact-address-box">
                                        <img src={ASSETS.CONTACTUS.Email} alt="" className="common-contact-icon email-icon" />
                                        <p className="title text-uppercase gb-color-red">mail here</p>
                                        <a href="mailto: info@geekybones.com" className="address-url gb-color-grey">info@geekybones.com</a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 pe-md-4 mb-md-5 mb-3">
                                    <div className="common-contact-address-box">
                                        <img src={ASSETS.CONTACTUS.Phone} alt="" className="common-contact-icon phone-icon" />
                                        <p className="title text-uppercase gb-color-red">call us now</p>
                                        <a href="tel: +91 172 417 2777" className="address-url gb-color-grey">+91 172 417 2777</a>
                                        <a href="tel: +91 172 417 2777" className="address-url gb-color-grey">+91 172 417 2777</a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 ps-md-4 mb-md-5 mb-3">
                                    <div className="common-contact-address-box">
                                        <img src={ASSETS.CONTACTUS.Skype} alt="" className="common-contact-icon skype-icon" />
                                        <p className="title text-uppercase gb-color-red">skype id</p>
                                        <a href="skype:Geeky BonesLab?chat" className="address-url gb-color-grey">Geeky BonesLab</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactDetails;