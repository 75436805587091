import * as React from "react";

const ContactUsForm = () => {
    return (
        <div className="col-md-12 mb-md-5 mb-3 mt-md-5 mt-3">
            <div className="contact-details-address-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="common-contact-us-header-wrapper">
                            <h3 className="gb-color-red title text-uppercase mb-md-4 mb-2">drop us your queries</h3>
                            <p className="gb-color-grey gb-description-med mb-md-5 mb-3">Just a few details from you and one of our representative shall get in touch with you soon.</p>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <form className="contact-us-form-wrapper">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="common-contact-input-field mb-md-4 mb-3">
                                        <label className="gb-color-grey mb-md-3 mb-2">Full Name</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="common-contact-input-field mb-md-4 mb-3">
                                        <label className="gb-color-grey mb-md-3 mb-2">Subject</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="common-contact-input-field mb-md-4 mb-3">
                                        <label className="gb-color-grey mb-md-3 mb-2">Email</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="common-contact-input-field mb-md-4 mb-3">
                                        <label className="gb-color-grey mb-md-3 mb-2">Mobile</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="common-contact-input-field">
                                        <label className="gb-color-grey mb-md-3 mb-2">Description</label>
                                        <textarea />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div
                                        className="mt-md-5 mt-4 text-center d-flex justify-content-center">
                                        <button className="gb-btn-lg-red">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUsForm;