import * as React from "react";

const AddressMap = () => {
    return (
        <section className="contact-us-wrapper-2">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.3610672321015!2d76.72852581548588!3d30.651959596571338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feef5e8f6e863%3A0xc3dfe43883da1c55!2sGeekyBones%20Lab%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1617085727136!5m2!1sen!2sin" loading="lazy"/>
        </section>
    )
}
export default AddressMap;