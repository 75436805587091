import * as React from "react";
import {ASSETS} from "../common/images";
import Breadcrumbs from "../common/breadcrumbs";

const Banner = () => {
    return (
 <section className="gb-banner-wrapper gb-contact-banner">
    <span className="common-header-shape-1" />
    <span className="common-header-shape-2" />
    <span className="common-header-shape-3" />
    <span className="common-header-shape-4" />
    <div className="container">
        <Breadcrumbs pageTitle={'Contact us'} currentPage={'Contact Us'} />
    </div>
 </section>
    )
}

export default Banner;
