import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/contact-us/banner";
import {ASSETS} from "../components/common/images";
import ContactDetails from "../components/contact-us/contact-details";
import ContactUsForm from "../components/contact-us/contact-us-form";
import AddressMap from "../components/contact-us/address-map";

const ContactPage = () => {
    return (
        <Layout>
            <Banner />
            <section className="contact-us-wrapper-1">
                <img src={ASSETS.HOME.DotsBox1} alt="" className="contact-us-dots" />
                <div className="container">
                    <div className="row">
                        <ContactDetails />
                        <ContactUsForm />
                    </div>
                </div>
            </section>
            <AddressMap />
        </Layout>
    )
}
export default ContactPage;
